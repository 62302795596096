import { ChevronRightIcon } from 'src/uikit/icons';

import { Link } from '../Link';
import s from './Breadcrumbs.module.scss';
import { type Breadcrumb } from './index';

type GoBackProps = {
  links: Breadcrumb[];
};

const getCurrentLinkForMobile = (
  currentBreadcrumbs: Breadcrumb[],
): { title: string; link: string } => {
  const links = currentBreadcrumbs.slice(0, -1);
  const backLink = links[links.length - 1]?.link;

  if (links.length > 1 && backLink) {
    return {
      title: 'Назад',
      link: backLink,
    };
  }

  return {
    title: 'Главная',
    link: '/',
  };
};

export function GoBack({ links }: GoBackProps) {
  const { title, link } = getCurrentLinkForMobile(links);

  return (
    <div>
      <Link
        id="go-to-back-link"
        href={link}
        className={s.back}
        data-qa="back_link"
        title={title}
        analyticsOptions={{
          action: link,
          clickZone: 'body',
          clickElement: 'breadcrumb',
          clickContent: title,
          uniqueId: 'go-to-back-link',
          transitionType: 'inside-link',
        }}
      >
        <ChevronRightIcon className={s.arrowLeftIcon} />
        <span>{title}</span>
      </Link>
    </div>
  );
}

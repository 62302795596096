import { elementScrollBy, windowScrollTo } from 'seamless-scroll-polyfill';

export const scrollToElementWithId = (
  id: string,
  offset?: number,
  scrollToEl?: {
    //Для скролла не внутри window, а внутри какого-то компонента
    elementID: string; //id элемента, до которого скроллим
    scrollBarId?: string; //id элемента, указывать
    scrollWrapperID: string; //id который вешаем на скроллбар
  },
) => {
  const element = document.getElementById(id);
  const header = document.querySelector('header');
  const anchorMenu = document.getElementById('anchor-menu');
  if (element) {
    const elCoordinates =
      element.getBoundingClientRect().top -
      document.body.getBoundingClientRect().top;
    const addHeight = anchorMenu ? anchorMenu.offsetHeight : 0;
    const headerHeight = header ? header.offsetHeight : 0;
    const baseOffset =
      window.scrollY > elCoordinates ? headerHeight + addHeight : addHeight;
    const customOffset = offset ?? baseOffset;

    if (
      !scrollToEl &&
      Math.abs(window.scrollY - (elCoordinates - customOffset)) > headerHeight
    ) {
      windowScrollTo(window, {
        behavior: 'smooth',
        top: elCoordinates - customOffset,
      });
    }
  }
  if (scrollToEl) {
    const scrollWrapper = document.getElementById(scrollToEl.scrollWrapperID);
    if (scrollWrapper) {
      const scrollBarEl = scrollToEl?.scrollBarId
        ? `#${scrollToEl.scrollBarId}`
        : '.ScrollbarsCustom-Scroller';
      const scrollBar = scrollWrapper.querySelector(scrollBarEl);
      const elForScroll = document.getElementById(scrollToEl.elementID);
      if (elForScroll && scrollBar) {
        const position =
          elForScroll.getBoundingClientRect().top -
          scrollWrapper.getBoundingClientRect().top;

        if (position) {
          elementScrollBy(scrollBar, {
            top: position,
            behavior: 'smooth',
          });
        }
      }
    }
  }
};

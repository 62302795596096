import { Wrapper } from 'src/components/Wrapper';

import s from './StatisticsBanner.module.scss';

interface StatisticsBannerProps {
  title: string;
  image: string;
  items: {
    value: string;
    description: string;
  }[];
  notes: string[];
}

export function StatisticsBanner({
  title,
  image,
  items,
  notes,
}: StatisticsBannerProps) {
  return (
    <section className={s.root}>
      <Wrapper>
        <div className={s.banner}>
          <h3 className={s.title}>{title}</h3>
          <div className={s.items}>
            {items.map((item, index) => (
              <div key={index} className={s.item}>
                <span className={s.value}>{item.value}</span>
                <p
                  className={s.description}
                  dangerouslySetInnerHTML={{ __html: item.description }}
                />
              </div>
            ))}
          </div>
          <img className={s.image} src={image} alt="abstract_image" />
        </div>
        {notes && notes.length > 1 && (
          <div data-attribute="no_search_index">
            {notes.map((item, index) => (
              <span className={s.note} key={index}>
                {item}
              </span>
            ))}
          </div>
        )}
      </Wrapper>
    </section>
  );
}

import { TabType } from '../Tabs';

export enum Type {
  Default = 'defualt',
  Tabs = 'tabs',
}

export type DemoSectionWithTabsProps = {
  tabs: TabType[];
  videos?: {
    link: string;
    id: string;
    previewImage?: string;
    controls?: boolean;
  }[];
  images?: {
    image: string;
    id: string;
    title?: string;
    subtitle?: string;
  }[];
  richTexts?: {
    id: string;
    richText: string;
  }[];
  className?: string;
  button?: {
    onClick: () => void;
    title: string;
    uniqueId: string;
  };

  image?: undefined;
  richText?: undefined;
  video?: undefined;
  contentLeft?: undefined;
};

export type DemoSectionDefaultProps = {
  richText: string;
  image?: string;
  video?: {
    controls?: boolean;
    link: string;
    previewImage?: string;
  };
  contentLeft?: boolean;
  button?: {
    onClick: () => void;
    title: string;
    uniqueId: string;
  };
  className?: string;

  tabs?: undefined;
  videos?: undefined;
  images?: undefined;
  richTexts?: undefined;
};

export type DemoSectionBase = {
  title: string;
  type?: Type;
  id?: string;
} & (DemoSectionDefaultProps | DemoSectionWithTabsProps);

export type DemoSectionProps = DemoSectionBase;

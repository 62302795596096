import cn from 'classnames';
import React from 'react';
import { Wrapper } from 'src/components/Wrapper';
import { ClickAnalyticsType } from 'src/types';
import { noop } from 'src/utils/noop';

import { Benefits } from '../Benefits';
import { Button } from '../Button';
import { Size, Variant } from '../Button/constants';
import { Admin, CloudUpload, User } from '../icons/ContentIcons/TechIcons';
import s from './PersonalManager.module.scss';

const MOCK = {
  title: 'Больше чем просто поддержка',
  description:
    'Полное сопровождение для решения ваших задач и понятный бизнес-результат',
  manager: {
    title: 'Индивидуальный подход к каждому клиенту',
    text: 'Проведем бесплатную консультацию по вашему проекту, ответим на вопросы и подберем лучшие решения.',
    img: 'https://cdn.cloud.ru/backend/images/personal_manager.png',
  },
  card: {
    title: 'Круглосуточно на связи',
    text: 'Бесплатная техподдержка 24/7, на связи всегда реальные люди.',
  },
  benefits: [
    {
      title: '1 400+ экспертов в области IT, кибербезопасности и AI',
      image: <Admin className={s.icon} />,
    },
    {
      title:
        'Проверенные методологии и лучшие практики бесшовной миграции в облако',
      image: <CloudUpload className={s.icon} />,
    },
    {
      title: 'Персональный менеджер для сопровождения ваших задач',
      image: <User className={s.icon} />,
    },
  ],
};

type BenefitType = {
  title: string;
  image?: JSX.Element;
};
interface ButtonProps {
  title: string;
  analyticsOptions: ClickAnalyticsType;
  link?: string;
  onClick?: () => void;
}
type CardType = {
  title: string;
  text?: string;
};

type ManagerCardType = {
  title?: string;
  text?: string;
  img?: string;
};

type ClassNamesType = {
  root?: string;
  wrapper?: string;
  header?: string;
  body?: string;
  manager?: string;
  content?: string;
  button?: string;
  img?: string;
  card?: string;
  benefits?: string;
};
export interface PersonalManagerProps {
  id?: string;
  title?: string;
  description?: string;
  manager?: ManagerCardType;
  card?: CardType;
  benefits?: BenefitType[];
  classNames?: ClassNamesType;

  button?: ButtonProps;
}

function DefaultBenefits({ className }: { className?: string }) {
  return (
    <Benefits
      cards={MOCK.benefits}
      className={cn(s.benefits, className)}
      grid={Benefits.grid.ThreeColumns}
    />
  );
}

export const PersonalManager = React.forwardRef<
  HTMLDivElement,
  PersonalManagerProps
>(
  (
    {
      id,
      title,
      description,
      manager,
      card,
      benefits,
      button,
      classNames,
      ...rest
    },
    ref,
  ) => {
    const curTitle = title ?? MOCK.title;
    const curDescription = description || MOCK.description;

    const curManager = {
      title: manager?.title ?? MOCK.manager.title,
      text: manager?.text || MOCK.manager.text,
      img: manager?.img || MOCK.manager.img,
    } satisfies ManagerCardType;

    const curCard = {
      title: card?.title ?? MOCK.card.title,
      text: card?.text || MOCK.card.text,
    } satisfies CardType;

    return (
      <>
        <section
          id={id}
          ref={ref}
          data-qa="section-personal-manager"
          data-attribute="no_search_index"
          className={cn(s.root, classNames?.root)}
          {...rest}
        >
          <Wrapper className={cn(s.wrapper, classNames?.wrapper)}>
            <div className={cn(s.header, classNames?.header)}>
              <h2
                className={s.title}
                dangerouslySetInnerHTML={{ __html: curTitle }}
              />
              {curDescription !== ' ' && (
                <p
                  className={s.description}
                  dangerouslySetInnerHTML={{ __html: curDescription }}
                />
              )}
            </div>

            <div className={cn(s.body, classNames?.body)}>
              <div className={cn(s.manager, s.box, classNames?.manager)}>
                <div className={cn(s.content, classNames?.content)}>
                  <div className={s.text}>
                    <h3
                      className={s.title}
                      dangerouslySetInnerHTML={{ __html: curManager.title }}
                    />
                    {curManager.text !== ' ' && (
                      <p
                        className={s.text}
                        dangerouslySetInnerHTML={{
                          __html: curManager.text,
                        }}
                      />
                    )}
                  </div>

                  {button && (
                    <Button
                      className={cn(s.button, classNames?.button)}
                      link={button.link}
                      variant={Variant.Primary}
                      size={Size.Big}
                      onClick={button.onClick || noop}
                      analyticsOptions={button.analyticsOptions}
                      data-qa={`personal-manager-button-${button.title}`}
                    >
                      {button?.title}
                    </Button>
                  )}
                </div>
                <img
                  className={cn(s.img, classNames?.img)}
                  src={curManager.img}
                  alt="manager"
                />
              </div>

              <div className={cn(s.card, s.box, classNames?.card)}>
                <h3
                  className={s.title}
                  dangerouslySetInnerHTML={{ __html: curCard.title }}
                />
                <p
                  className={s.text}
                  dangerouslySetInnerHTML={{ __html: curCard.text }}
                />
              </div>
            </div>
          </Wrapper>
        </section>

        {benefits ? (
          <Benefits
            cards={benefits}
            className={cn(s.benefits, classNames?.benefits)}
            grid={Benefits.grid.ThreeColumns}
          />
        ) : (
          <DefaultBenefits className={classNames?.benefits} />
        )}
      </>
    );
  },
);

PersonalManager.displayName = 'PersonalManager';

import cn from 'classnames';
import {
  AdvantagesCarousel,
  ClientsCarousel,
} from 'src/components/Carousel/Carousels';
import { StatisticsBanner } from 'src/components/Restyling/ConstructorBlocks/StatisticsBanner';
import { Title } from 'src/components/Restyling/ConstructorBlocks/Title';
import { Seo } from 'src/components/Seo';
import { VideoPlayer } from 'src/components/VideoPlayer';
import { Wrapper } from 'src/components/Wrapper';
import { useGtmDataLayer } from 'src/hooks/useGtmDataLayer';
import { useIntersectionObserveForms } from 'src/hooks/useIntersectionObserverForms';
import { Layout } from 'src/layout/index';
import { MARK_EVENT_ABOUT } from 'src/markevents';
import { organizationMicroData } from 'src/scripts';
import { Benefits } from 'src/uikit/Benefits';
import { Breadcrumbs } from 'src/uikit/Breadcrumbs';
import { Button } from 'src/uikit/Button';
import { ClientCasesCarousel } from 'src/uikit/Carousels/ClientCasesCarousel';
import { DemoSection } from 'src/uikit/DemoSection';
import { Form } from 'src/uikit/Form';
import {
  BrowserCode,
  CloudIaaS,
  Clouds,
  RocketFly,
  Ruble,
} from 'src/uikit/icons/ContentIcons/TechIcons';
import { Link } from 'src/uikit/Link';
import { PersonalManager } from 'src/uikit/PersonalManager';
import { scrollToElementWithId } from 'src/utils/scrollToElementWithId';

import s from './About.module.scss';

const HERO_DATA = {
  title: '№1 облако для бизнеса',
  description: 'Персональные решения и экспертная поддержка ваших бизнес-задач',
  buttonTitle: 'Получить консультацию',
  image: 'https://cdn.cloud.ru/backend/images/about/preview.png',
  video: 'https://cdn-video.cloud.ru/backend/video/about-page/hero.mp4',
};

const FEATURE_CARDS = {
  note: 'Мы создаем персональные решения, оказываем экспертную поддержку и решаем задачи бизнеса любого масштаба:<br/>от ритейла и телекома до тяжелой промышленности и банков.',
};

const CLIENTS = {
  title: 'Помогаем клиентам внедрять инновации',
  slides: [
    {
      id: 'client2',
      image: 'https://cdn.cloud.ru/backend/cases/magnit.png?w=146&h=27',
    },
    {
      id: 'client4',
      image:
        'https://cdn.cloud.ru/backend/images/pages/index/carousels/new-clients/Dodo.svg?w=126&h=56',
    },
    {
      id: 'client6',
      image:
        'https://cdn.cloud.ru/backend/images/pages/index/carousels/new-clients/Pyatorochka.svg?w=146&h=56',
    },
    {
      id: 'client8',
      image:
        'https://cdn.cloud.ru/backend/images/pages/index/carousels/new-clients/Ruspharm.svg?w=126&h=56',
    },
    {
      id: 'client10',
      image:
        'https://cdn.cloud.ru/backend/images/pages/index/carousels/new-clients/MoscowBook.svg?w=47&h=56',
    },
    {
      id: 'client11',
      image:
        'https://cdn.cloud.ru/backend/images/pages/index/carousels/new-clients/Modis.svg?w=103&h=56',
    },
    {
      id: 'client12',
      image:
        'https://cdn.cloud.ru/backend/images/pages/index/carousels/new-clients/Crafter.svg?w=145&h=56',
    },

    {
      id: 'client14',
      image:
        'https://cdn.cloud.ru/backend/images/pages/index/carousels/new-clients/Media_Scope.svg?w=50&h=56',
    },
    {
      id: 'client15',
      image:
        'https://cdn.cloud.ru/backend/images/pages/index/carousels/new-clients/Rabora.ru.svg?w=133&h=56',
    },
  ],
};

const BENEFITS = {
  title: 'Используйте преимущества облака для роста и развития бизнеса',
  items: [
    {
      title: 'Оптимизируйте расходы на IT-инфраструктуру',
      text: 'Различные модели оплаты — pay‑as‑you‑go или pay‑as‑you‑allocate. Платите только за те ресурсы, которыми пользуетесь',
      icon: <Ruble />,
    },
    {
      title: 'Сокращайте time to market ваших продуктов',
      text: 'Быстрый тест гипотез и запуск MVP в облаке позволяют минимизировать инвестиции и оперативно получить результат',
      icon: <RocketFly />,
    },
    {
      title: 'Контролируйте процессы в единой консоли',
      text: 'Удобное управление и моментальное масштабирование инфраструктуры в облаке',
      icon: <BrowserCode />,
    },
    {
      title: 'Настраивайте облачные сервисы под ваши задачи',
      text: 'Широкий портфель сервисов для решения задач по вычислениям, аналитике данных, разработке приложений и не только',
      icon: <Clouds />,
    },
    {
      title: 'Внедряйте инновации и задавайте тренды в индустрии',
      text: 'Доступ к ресурсам уникальных суперкомпьютеров и маркетплейс готовых AI-сервисов',
      icon: <CloudIaaS />,
    },
  ],
};

const CAROUSEL = {
  title: 'Кейсы наших клиентов',
  slides: [
    {
      text: 'Ритейлер сэкономил 25% бюджета, используя нейросеть при создании рекламного ролика и не привлекая селебрити.',
      image:
        'https://cdn.cloud.ru/backend/images/pages/index/carousels/new-clients/Pyatorochka.svg',
      link: '/cases/pyaterochka-sozdala-deepfake-reklamu-na-platforme-ml-space',
    },
    {
      text: 'Задачей было развернуть приложение «1С:Бухгалтерия» в облаке и сократить время выгрузки отчета в 2 раза. В результате команда ускорила работу систем и сократила время выгрузки отчета с 5 до 2,5 часов',
      image:
        'https://cdn.cloud.ru/backend/solutions/cases/retail/case-logo-1.png',
      link: '/cases/1s-buhgalteriya-dodo-brands-v-oblake-sbercloud',
    },
    {
      text: 'Digital-агентство запустило проект в облаке: увеличило объем ресурсов в четыре раза за один час. При пиковой нагрузке зафиксировали 75 000 активных пользователей на сайте.',
      image:
        'https://cdn.cloud.ru/backend/images/pages/promo/products/remarketing/slides/MilonAsk.svg',
      category: 'IT-разработка',
      link: '/cases/milonask-razmestilo-v-oblake-vysokonagruzhennyj-sajt',
    },
    {
      text: 'CRAFTER.ONLINE стабильно работает при скачках трафика, кибератаках и других уязвимостях, а пользователь платформы получает информацию в любое время суток и возможность выбирать рейсы за 30 секунд',
      image: 'https://cdn.cloud.ru/backend/images/about/crafter.png',
      link: '/cases/crafter-online-migrirovala-s-aws-v-cloud',
    },
  ],
};

const LINKS = {
  title: 'Станьте частью сообщества',
  links: [
    {
      title: 'Telegram-канал Cloud.ru',
      description:
        'Узнавайте о лучших практиках внедрения облака и актуальных IT-трендах',
      link: 'https://t.me/+eTt4oI9s2GhhMWQy',
      img: 'https://cdn.cloud.ru/backend/images/about/qr_main.svg',
    },
    {
      title: 'Telegram-канал Cloud.ru Tech',
      description:
        'Собираем IT-специалистов и совместно делимся лучшими практиками честно и с душой',
      link: 'https://t.me/+oDkLphbVaCdmOGQy',
      img: 'https://cdn.cloud.ru/backend/images/about/qr_tech.svg',
    },
  ],
};

const SEO = {
  title: 'О компании Cloud.ru - №1 облако для бизнеса',
  description:
    'Cloud.ru лидер отрасли в области решений для резервного копирования, восстановления и управления данными. Cloud.ru стремится предоставить вашему бизнесу наиболее надежную платформу для защиты данных',
  ogTitle: 'О компании Cloud.ru - №1 облако для бизнеса',
  ogDesc:
    'Cloud.ru лидер отрасли в области решений для резервного копирования, восстановления и управления данными. Cloud.ru стремится предоставить вашему бизнесу наиболее надежную платформу для защиты данных.',
};

const DEMO_SECTION = {
  title: 'Ваши данные всегда надежно защищены',
  richText: `
    <ul>
    <li>Наличие лицензий Роскомнадзора, ФСБ, ФСТЭК</li>
    <li>Соответствие инфраструктуры 152-ФЗ, 187-ФЗ, PCI DSS, ISO</li>
    <li>Устойчивость к пиковым нагрузкам: более&nbsp;900 000 vCPU</li>
  </ul>
`,
  video: {
    link: 'https://cdn-video.cloud.ru/backend/video/security/data_center_cloud.mp4',
    previewImage:
      'https://cdn.cloud.ru/backend/images/oblako-dlya-krupnogo-biznesa/preview.png',
  },
};

const STATISTICS = {
  title: 'Ключевые выгоды',
  image: 'https://cdn.cloud.ru/backend/images/about/stat_img.png',
  items: [
    { value: '30%', description: 'Средняя экономия на инфраструктуре**' },
    {
      value: '56%',
      description: 'Снижение операционных затрат в облаке за 5 лет***',
    },
    { value: '75%', description: 'Рост эффективности IT&#8209;команд***' },
  ],
  notes: [
    '**В облаке в сравнении с локальной инфраструктурой, по данным исследования облачной зрелости российского бизнеса от 2023 года',
    '***Источник: IDC — исследование использования облака среди крупных предприятий из разных отраслей в течение пяти лет',
  ],
};

function ArrowIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M5.11264 15.1645L3.88574 13.9376L12.2087 5.61466H4.93555V3.8855H15.1647V14.1147H13.4356V6.84158L5.11264 15.1645Z"
        fill="#26D07C"
      />
    </svg>
  );
}

function CustomHeroBlock({ onClick }: { onClick: () => void }) {
  const breadcrumbsLinks = [
    {
      title: 'Главная',
      link: '/',
    },
    {
      title: 'Почему Cloud.ru',
    },
  ];

  return (
    <section className={s.customHeroBlock}>
      <Wrapper>
        <Breadcrumbs
          className={s.customHeroBlockBreadcrumbs}
          links={breadcrumbsLinks}
        />
        <div className={s.customHeroBlockGrid}>
          <div>
            <h1
              className={s.customHeroBlockTitle}
              dangerouslySetInnerHTML={{
                __html: HERO_DATA.title,
              }}
            />
            <div
              className={s.customHeroBlockDescription}
              dangerouslySetInnerHTML={{ __html: HERO_DATA.description }}
            />
            <div className={s.buttonsWrapper}>
              <Button
                className={s.customHeroBlockButton}
                onClick={onClick}
                analyticsOptions={{
                  action: 'click',
                  clickZone: 'body',
                  clickElement: 'button',
                  clickContent: HERO_DATA.buttonTitle,
                  uniqueId: 'hero-button-about',
                  transitionType: 'inside-link',
                }}
              >
                {HERO_DATA.buttonTitle}
              </Button>
            </div>
          </div>
          <div>
            <VideoPlayer
              autoPlay
              muted
              withOutError
              loop
              playsInline
              videoClassName={s.customHeroBlockVideo}
              previewImage={HERO_DATA.image}
              className={s.customHeroBlockVideo}
              link={HERO_DATA.video}
            />
          </div>
        </div>
      </Wrapper>
    </section>
  );
}

function LinkItems() {
  const { sendDataToDataLayer } = useGtmDataLayer();

  return (
    <section className={s.links}>
      <Title className={s.linksTitle} title={LINKS.title} />
      <Wrapper>
        <div className={s.linksItems}>
          {LINKS.links.map((item, index) => (
            <Link
              key={index}
              className={cn('link', s.linksItem)}
              href={item.link}
              onClick={() => {
                sendDataToDataLayer({
                  dataLayer: {
                    event: 'custom_event',
                    event_category: 'click',
                    event_action:
                      index === 0 ? 'tg_cloud.ru' : 'tg_cloud.ru_tech',
                    event_label: 'tg',
                  },
                });
              }}
              id={`about-links-click-${item.title}`}
              analyticsOptions={{
                action: item.link,
                clickZone: 'body',
                clickElement: 'cardclick',
                clickContent: item.title,
                uniqueId: `about-links-click-${item.title}`,
                transitionType: 'inside-link',
              }}
            >
              <img
                className={s.linksItemImg}
                src={item.img}
                alt="qr-код"
                data-qa="qr-image"
              />
              <div className={s.linksItemContent}>
                <h4 className={s.linksItemTitle}>
                  {item.title}
                  <ArrowIcon />
                </h4>
                <p className={s.linksItemDescription}>{item.description}</p>
              </div>
            </Link>
          ))}
        </div>
      </Wrapper>
    </section>
  );
}

function About() {
  const { formRef, setAnchorId } = useIntersectionObserveForms();
  const { sendDataToDataLayer } = useGtmDataLayer();

  const onButtonClickToForm = (anchorId: string) => {
    scrollToElementWithId('form');
    sendDataToDataLayer({
      dataLayer: {
        event: 'custom_event',
        event_category: 'consultation LeadForm',
        event_action: 'scroll_to_form',
        event_label: 'about_company',
      },
    });

    setAnchorId(anchorId);
  };

  return (
    <Layout withConsultationButton={false}>
      <Seo title={SEO.title} description={SEO.description}>
        <meta key="og:title" property="og:title" content={SEO.ogTitle} />
        <meta
          key="og:description"
          property="og:description"
          content={SEO.ogDesc}
        />

        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(organizationMicroData),
          }}
        />
      </Seo>
      <div className={s.root}>
        <CustomHeroBlock
          onClick={() => {
            onButtonClickToForm('about_hero_anchor_to_form_hero');
          }}
        />
        <AdvantagesCarousel note={FEATURE_CARDS.note} />
        <ClientsCarousel title={CLIENTS.title} slides={CLIENTS.slides} />
        <StatisticsBanner
          title={STATISTICS.title}
          image={STATISTICS.image}
          items={STATISTICS.items}
          notes={STATISTICS.notes}
        />
        <Benefits title={BENEFITS.title} cards={BENEFITS.items} />
        <DemoSection
          title={DEMO_SECTION.title}
          richText={DEMO_SECTION.richText}
          video={DEMO_SECTION.video}
          contentLeft
        />
        <PersonalManager
          button={{
            title: 'Получить консультацию',
            onClick: () =>
              onButtonClickToForm('about_hero_anchor_to_form_task'),
            analyticsOptions: {
              action: 'click',
              clickZone: 'body',
              clickElement: 'button',
              clickContent: 'Получить консультацию',
              uniqueId: 'about-personal-manager-button-scroll-to-form',
              transitionType: 'inside-link',
            },
          }}
        />
        <ClientCasesCarousel title={CAROUSEL.title} slides={CAROUSEL.slides} />
        <Form
          id="form"
          title="Получите бесплатную консультацию по вашему проекту"
          ref={formRef}
          inputPath="v2/order"
          additionalFields={{ subsegment: 'consultation' }}
          marketingEventIDs={MARK_EVENT_ABOUT}
          consultationFormType="body"
          allFormsSentFormType="consultation"
          tryFormType="consultation"
          formVariant="inner"
          dataLayer={{
            event: 'custom_event',
            event_category: 'consultation LeadForm',
            event_action: 'formSuccess',
            event_label: 'about_company',
          }}
          analyticsFieldFill={{ formType: 'body' }}
        />
        <LinkItems />
      </div>
    </Layout>
  );
}

export default About;

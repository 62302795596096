import { DefaultDemoSection } from './DefaultDemoSection';
import { DemoSectionWithTabs } from './DemoSectionWithTabs';
import { DemoSectionProps, Type } from './types';

export function DemoSection({
  id,
  title,
  image,
  images,
  richText,
  video,
  videos,
  richTexts,
  tabs,
  button,
  contentLeft,
  type = Type.Default,
  className = '',
}: DemoSectionProps) {
  return (
    <>
      {type === Type.Default ? (
        <DefaultDemoSection
          title={title}
          image={image}
          richText={richText ?? ''}
          video={video}
          button={button}
          id={id}
          contentLeft={contentLeft}
          className={className}
        />
      ) : (
        <DemoSectionWithTabs
          title={title}
          videos={videos}
          images={images}
          richTexts={richTexts}
          tabs={tabs ?? []}
          id={id}
          className={className}
        />
      )}
    </>
  );
}

DemoSection.type = Type;

import cn from 'classnames';
import { useWindowWidth } from 'src/hooks/useWindowWidth';
import { ChevronRightIcon } from 'src/uikit/icons';

import { Link } from '../Link';
import s from './Breadcrumbs.module.scss';
import { Color } from './constants';
import { GoBack } from './GoBack';

export type Breadcrumb = {
  title?: string;
  link?: string;
};

export interface BreadcrumbsProps {
  links: Breadcrumb[];
  color?: Color;
  positionAbsolute?: boolean;
  className?: string;
}

export function Breadcrumbs({
  links,
  color = Color.Graphite,
  positionAbsolute = false,
  className,
}: BreadcrumbsProps) {
  const {
    windowWidth,
    breakpoints: { MD },
  } = useWindowWidth();

  return (
    <div
      className={cn(s.wrapper, className, {
        [s.absolute]: positionAbsolute,
      })}
      data-qa="breadcrumbs_wrapper"
    >
      <ul
        className={cn(s.root, s[color], {
          [s.hiddenMobile]: windowWidth && windowWidth < MD,
        })}
        itemScope
        itemType="https://schema.org/BreadcrumbList"
      >
        {links.map(({ title = '', link }, index) => {
          if (link) {
            return (
              <li
                key={index}
                itemProp="itemListElement"
                itemScope
                itemType="https://schema.org/ListItem"
              >
                <Link
                  id={`breadcrumb-link-${title}`}
                  href={link}
                  className={s.link}
                  data-qa="section_link"
                  itemProp="item"
                  title={title}
                  analyticsOptions={{
                    action: link,
                    clickZone: 'body',
                    clickElement: 'breadcrumb',
                    clickContent: title,
                    uniqueId: `breadcrumb-link-${title}`,
                    transitionType: 'inside-link',
                  }}
                >
                  <span itemProp="name">{title}</span>
                  <meta itemProp="position" content={`${index}`} />
                  <ChevronRightIcon className={s.chevronIcon} />
                </Link>
              </li>
            );
          }

          return (
            <li
              key={index}
              className={s.item}
              data-qa="breadcrumb"
              itemProp="itemListElement"
              itemScope
              itemType="https://schema.org/ListItem"
            >
              <span itemProp="name">{title}</span>
              <meta itemProp="position" content={`${index}`} />
            </li>
          );
        })}
      </ul>
      {windowWidth && windowWidth <= MD && <GoBack links={links} />}
    </div>
  );
}

Breadcrumbs.colors = Color;

import cn from 'classnames';
import { VideoPlayer } from 'src/components/VideoPlayer';
import { Wrapper } from 'src/components/Wrapper';
import { Button } from 'src/uikit/Button';

import { DemoSectionDefaultProps } from '../types';
import s from './DefaultDemoSection.module.scss';

function CustomPlayButton() {
  return (
    <div className={s.playButton}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
      >
        <path d="M14 28V12L30 20L14 28Z" fill="#26D07C" />
      </svg>
    </div>
  );
}

export function DefaultDemoSection({
  title,
  image,
  richText,
  video,
  button,
  id,
  contentLeft,
  className,
}: Omit<DemoSectionDefaultProps, 'tabs' | 'videos' | 'images'> & {
  title: string;
  id?: string;
}) {
  const controls = video?.controls === undefined ? true : video.controls;

  return (
    <section id={id} className={cn(s.root, className)}>
      <Wrapper className={s.wrapper}>
        <div
          className={cn(s.grid, {
            [s.reverse]: contentLeft,
          })}
        >
          <div
            className={cn(s.mediaWrapper, {
              [s.isVideo]: video && !image,
            })}
          >
            {image && !video && <img src={image} alt={title} />}

            {video && !image && (
              <VideoPlayer
                className={s.videoBlockWrapper}
                videoClassName={s.video}
                link={video.link}
                data-qa="section_video"
                previewImage={video.previewImage}
                customError
                controls={controls}
                loop={!controls}
                muted={!controls}
                customPlayButton={<CustomPlayButton />}
              />
            )}
          </div>
          <div className={s.content}>
            <h3 className={s.title}>{title}</h3>

            <div
              className={s.contentText}
              dangerouslySetInnerHTML={{ __html: richText }}
            />

            {button && (
              <Button
                onClick={button.onClick}
                className={s.button}
                analyticsOptions={{
                  action: 'click',
                  clickZone: 'body',
                  clickElement: 'button',
                  clickContent: button.title,
                  uniqueId: `demo-section-${button.uniqueId}`,
                  transitionType: 'inside-link',
                }}
              >
                {button.title}
              </Button>
            )}
          </div>
        </div>
      </Wrapper>
    </section>
  );
}

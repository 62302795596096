export const MARK_EVENT_CLOUD_MATURITY = [
  'f9dae490-32b4-4d07-8a8a-3efd36641810',
];

export const MARK_EVENT_GPU = ['6217ea17-bbf1-40bb-8a2c-a1a57bd9a245'];

export const MARK_EVENT_OBUCHENIE_ADVANCED = [
  'a9f937b8-1208-41ea-9fd3-4a3e26e8dd65',
];

export const MARK_EVENT_CERTIFICATION_ADVANCED = [
  '4d4253f8-d218-43e9-87a5-1928aa4e2488',
];

export const MARK_EVENT_EDUCATION_EAB = [
  '63a13fc5-8742-4b07-bf8f-b4fd25118cc4',
];

export const MARK_EVENT_EDUCATION_EAE = [
  '4308b9f4-ed7d-44a8-86e7-b4d811b2d022',
];

export const MARK_EVENT_EDUCATION_MLSPACE = [
  'fd8ebb7b-401d-4be4-aa21-7c077f8f12e3',
];

export const MARK_EVENT_EDUCATION_PROFESSIONAL = [
  '1d9e4051-48d3-4dc7-b716-1bd1bd946d4b',
];

export const MARK_EVENT_ABOUT = ['1027d361-7480-4fb7-8809-3afe989d1675'];

export const MARK_EVENT_STRUKTURA_IT = ['2dc1fb29-8909-4d9c-a3b3-57d134e0bdf8'];

export const MARK_EVENT_OFFERS_ADVANCED_PROMO = [
  '643a0bb5-2b40-425f-9979-dc2b8a6546d6',
];

export const MARK_EVENT_ISSLEDOVANIE_CLOUD_TECH = [
  'c63582ce-5893-4a9a-9f14-2e2bc972aaa8',
];

export const MARK_EVENT_FREE = ['92cc2499-07aa-491a-b8c8-41157de7ba51'];

export const MARK_EVENT_GPU_PROMO = ['018daef6-5041-7a04-ac18-d3ac5b95ede9'];

export const MARK_EVENT_MIGRATION = ['018e55ee-66bb-767a-a152-510351e0113e'];

export const MARK_EVENT_FREE_TEST = ['0190107d-6933-7d27-8e4e-e473311bbc94'];

export const MARK_EVENT_STARTUPS = ['0190cae9-5b02-73c1-92a3-06eb3155dd09'];

export const MARK_EVENT_WANTRESULT = ['35085f3b-27bf-4d15-9013-b1c850e5f3f6'];

export const MARK_EVENT_COURSE_FOUNDAMENTALS_EVOLUTION = [
  '01917f1f-fc24-7056-b439-bc12ef7ac169',
];

export const MARK_EVENT_SERTIFICATION_FUNDAMENTALS = [
  '01917f1e-f1ef-7bc1-a72f-02ded65031a8',
];

export const MARK_EVENT_KURS_FUNDAMENTALS = [
  '01917f1c-0136-78cf-a15f-cbfd450a8059',
];

export const MARK_EVENT_KUBERNETES = ['019253e5-404f-7c80-b468-01c0de220716'];
